import { ClickAwayListener } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Menu from "@mui/icons-material/Menu";
import classNames from "classnames";
import { ThemeProvider } from "@mui/material/styles";
import React, {
  PropsWithChildren,
  SyntheticEvent,
  useState,
  useEffect,
} from "react";
import classnames from "classnames";

import { HeaderSubNavButtonCard } from "../HeaderSubNavButtonCard";
import { HeaderSubNavLinksCard } from "../HeaderSubNavLinksCard";
import { BookNowButton } from "../BookNowButton";
import Hidden from "@mui/material/Hidden";
import { ScrollDirections, useScrollDirection } from "../../../hooks/hooks";
import { LocationsLineItems } from "../BookingForm";
import { ButtonStyle } from "../FHButton";
import { SubNavLinkItem } from "../SubNavigationBar";
import { SlidingMobileMenu } from "../SlidingMobileMenu";
import { useActiveExperimentsStateSelector } from "@/store/selectors";
import SereneUI from "src/themes/SereneUI";

const useStyles = (
  paletteType: "light" | "dark",
  headerAlwaysVisible: boolean,
  bookNowVisible: boolean,
  bannerStickyBar?: React.ReactNode,
) =>
  makeStyles((theme) => {
    const color =
      paletteType === "dark"
        ? theme.palette.text.primary
        : theme.palette.background.paper;

    return {
      root: {
        position: headerAlwaysVisible ? "fixed" : "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1300,
        color,
        transition: bannerStickyBar
          ? "transform .5s ease"
          : "transform .3s ease",
        background:
          "linear-gradient(180deg, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0) 100%)",

        "&:before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          transition: "background .3s ease",
        },
      },
      headerLogo: {
        color,
        padding: theme.spacing(1, 2),
        paddingLeft: 0,
        margin: 0,
        lineHeight: 1,
      },
      headerContainer: {
        position: "relative",
        minHeight: 80,

        [theme.breakpoints.up("lg")]: {
          minHeight: 100,
        },
      },
      headerNav: {
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        height: 100,
        padding: theme.spacing(2, 0),
        boxSizing: "border-box",

        [theme.breakpoints.up(1140)]: {
          display: "flex",
        },
        "& >ul": {
          padding: 0,
          margin: 0,
          display: "inline-flex",
          alignItems: "center",
          "& >li": {
            listStyleType: "none",
          },
        },
      },
      headerNavHover: {
        "& >ul": {
          "& >li": {
            "&:hover": {
              "& $linkHasDropdown": {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                borderRadius: `4px 4px 0 0`,
                alignSelf: "flex-end",
                padding: theme.spacing(2),
                position: "relative",
                zIndex: 5,
              },
              "& $expandedContent": {
                display: "flex !important",
              },
            },
          },
        },
      },
      headerButtons: {
        [theme.breakpoints.up("md")]: {
          minWidth: 270,
          textAlign: "right",
        },
        "& button": {
          [theme.breakpoints.between(1280, 1367)]: {
            paddingLeft: `${theme.spacing(1.5)} !important`,
            paddingRight: `${theme.spacing(1.5)} !important`,
          },
        },
        "& > div": {
          color,
          borderColor: color,
          "& + button, & + div, & + a": {
            marginLeft: theme.spacing(2),
          },
        },
        "& > a": {
          color,
          borderColor: color,
          "& + button, & + a": {
            marginLeft: theme.spacing(2),
          },
        },
        "& > button:last-of-type, & > a:last-of-type": {
          ...(bookNowVisible && {
            marginRight: theme.spacing(2),
            [theme.breakpoints.down(1367)]: {
              marginRight: theme.spacing(1),
            },
          }),
        },
      },
      headerButtonsExp: {
        "& > a": {
          color: `${theme.palette.text.primary} !important`,
          "&:before": {
            borderColor: "#111 !important",
          },
          "&:hover": {
            "&:before": {
              borderColor: "#111 !important",
            },
          },
        },
        "& > button": {
          color: `${theme.palette.text.primary} !important`,
          "&:before": {
            borderColor: "#111 !important",
          },
          "&:after": {
            display: "none",
          },
          "&:hover, &:focus": {
            "&:before": {
              borderColor: "#111 !important",
            },
          },
        },
      },
      menuButtonContainer: {
        display: "flex",
        alignItems: "center",

        "&::before": {
          content: '""',
          borderLeft: "1px solid white",
          marginLeft: theme.spacing(2),
          display: "inline-block",
          height: 30,
          [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(1.2),
          },
        },

        [theme.breakpoints.up(1140)]: {
          display: "none",
        },
      },
      singleMenuButtonContainer: {
        display: "flex",
        alignItems: "center",

        "&::before": {
          content: '""',
          marginLeft: theme.spacing(2),
          display: "inline-block",
          height: 30,
          [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(1.2),
          },
        },

        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      },
      menuButton: {
        color,
        fontSize: 11,
        marginRight: theme.spacing(-0.9),
        paddingLeft: theme.spacing(1.1),

        "& .MuiIconButton-label": {
          flexDirection: "column",
        },
      },
      headerLink: {
        display: "flex",
        color,
        padding: theme.spacing(1, 1.2),
        textDecoration: "none",
        [theme.breakpoints.down(1367)]: {
          padding: theme.spacing(1, 0.8),
        },
      },
      headerLinkRoot: {
        color: "#333333",
      },
      headerLinkExpanded: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRadius: `4px 4px 0 0`,
        alignSelf: "flex-end",
        padding: theme.spacing(2),
        position: "relative",
        zIndex: 5,
      },
      headerLinkExpandedExp: {
        backgroundColor: "transparent",
        textDecoration: "underline",
      },
      expandedContent: {
        color: theme.palette.text.primary,
        zIndex: 3,
        position: "absolute",
        top: `calc(100% - ${theme.spacing(2)})`,
        left: 0,
        right: 0,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        width: "100%",
        boxShadow: theme.shadows[4],
        flexDirection: "row",
        display: "flex",
        justifyContent: "flex-start",
        minWidth: 300,
      },
      card: {
        "& img": {
          borderRadius: theme.spacing(1),
          maxWidth: "100%",
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        },

        "& > .MuiButton-root": {
          marginTop: theme.spacing(2),
        },
      },
      subnavContainer: {
        marginLeft: theme.spacing(5),
      },
      subnavHeader: {
        marginBottom: theme.spacing(2),
      },
      subnavLinks: {
        marginTop: theme.spacing(2),
      },
      cardActions: {
        alignItems: "flex-end",
        flexDirection: "column",
        justifyContent: "space-between",
        marginTop: "auto",
        borderTop: `1px solid ${theme.palette.divider}`,
        "& > button:not(:last-child)": {
          margin: theme.spacing(0, 0, 1, 0),
        },
      },
      title: {
        color: theme.palette.secondary.main,
        textAlign: "center",
      },
      smallMenuLink: {
        border: "1px solid " + theme.palette.divider,
        width: "100%",
        minWidth: 225,
        padding: theme.spacing(0.2, 0.5),
        "& .MuiButton-label": {
          justifyContent: "space-between",
        },
        "& .actual-label": {
          width: "100%",
        },
        "& svg": {
          borderLeft: "1px solid " + theme.palette.action.active,
          boxSizing: "content-box",
          color: theme.palette.action.active,
          padding: theme.spacing(0, 0.8),
        },
      },
      containedButton: {
        color: theme.palette.background.paper,
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
          color: theme.palette.background.paper,
          borderColor: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.main,
        },
      },
      outlinedButton: {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        "&:hover": {
          color: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
        },
      },
      flexAdjust: {
        flex: 1,
        "& >div": {
          height: "auto",
        },
      },
      relative: {
        position: "relative",
      },
      centered: {
        left: "50%",
        transform: "translateX(-50%)",
      },
      scrolled: {
        transform: bannerStickyBar
          ? "translateY(-188px)"
          : "translateY(-100px)",
        [theme.breakpoints.up("md")]: {
          transform: bannerStickyBar
            ? "translateY(-150px)"
            : "translateY(-100px)",
        },
      },
      sticky: {
        transform: "translateY(0)",
      },
      solidBackground: {
        "&:before": {
          background: theme.palette.primary.dark,
        },
      },
      solidWhiteBackground: {
        boxShadow: "0px 1px 7px 0 rgba(0,0,0,0.2)",
        "&:before": {
          background: theme.palette.common.white,
        },
      },
      solidBackgroundWithGradient: {
        "&:before": {
          background: `linear-gradient(180deg, rgba(0,0,0,0.45) 0%, ${theme.palette.primary.dark} 100%)`,
        },
      },
      bookNowWidgetContainer: {
        background: theme.palette.background.paper,
        width: "100%",
        position: "absolute",
        boxShadow: "0px 1px 7px 0 rgb(0 0 0 / 20%)", // same as paper shadow
      },
      logo: {
        [theme.breakpoints.down("md")]: {
          maxWidth: 108,
          maxHeight: 48,
          "& *": {
            width: "100%",
          },
        },
      },
      ssr: {
        display: "none",
      },
      locationMap: {
        "& >div": {
          padding: 0,
          margin: 0,
          "& >div": {
            [theme.breakpoints.down("md")]: {
              borderRadius: 0,
              minWidth: 320,
            },
          },
        },
      },
      containerRoot: {
        marginBottom: 0,
      },
      linkHasDropdown: {},
      customContainerRoot: {
        maxWidth: "1180px !important",
        paddingLeft: "24px !important",
        paddingRight: "24px !important",
      },
      menuButtonContainerExp: {
        "&:before": {
          borderColor: "#d1d1d1",
        },
      },
      menuButtonDark: {
        color: "#333",
      },
      expandedContentExp: {
        top: `calc(100% - ${theme.spacing(0)})`,
      },
      bookNowCTAStickyRoot: {
        "& button": {
          [theme.breakpoints.between(1280, 1367)]: {
            paddingLeft: `${theme.spacing(3)} !important`,
            paddingRight: `${theme.spacing(3)} !important`,
          },
        },
        "& > button:last-of-type, & > a:last-of-type": {
          [theme.breakpoints.down(1367)]: {
            marginRight: theme.spacing(2),
          },
        },
      },
      bookNowCTASticky: {
        "& > button:last-of-type, & > a:last-of-type": {
          marginRight: theme.spacing(0),
        },
      },
    };
  })();

export type HeaderLinkBase = {
  label: string;
  url: string;
};

export type HeaderCard = {
  title: string;
  imgUrl: string;
  description: string;
  link: HeaderLinkBase;
};

export type NavigationBase = {
  text: string;
  link: string;
};

export type HeaderSubnav = {
  title: string;
  text?: string;
  description?: string;
  links: HeaderLinkBase[];
  link?: NavigationBase[];
};

export type HeaderLink = HeaderLinkBase & {
  content?: {
    cards?: HeaderCard[];
    subnavs?: HeaderSubnav[];
  };
  showLocationMap?: boolean;
};

export type HeaderProps = {
  Logo?: React.FC;
  MobileLogo?: React.FC;
  Buttons?: React.FC;
  MobileButtons?: React.FC;
  links?: HeaderLink[];
  mobileLinks?: SubNavLinkItem[];
  singleMobileLink?: SubNavLinkItem[];
  theme?: "light" | "dark";
  headerAlwaysVisible?: boolean;
  bookNowLabel?: string;
  bookNowStyle?: ButtonStyle;
  bookNowBookingWidget?: React.FC | JSX.Element;
  bookNowMobileLabel?: string;
  locationMap?: JSX.Element;
  setHoverLocationId?(id: string): void;
  hoverLocationId?: string;
  sortedLocations?: LocationsLineItems | undefined;
  tabs?: {
    locationMapLabel: string;
    locationListLabel: string;
  };
  bannerStickyBar?: React.ReactNode;
  viewAllLocation?: {
    label: string;
    url: string;
  };
  isBrowser?: boolean;
  isInterstitialPage?: boolean;
  isBannerStickyBar?: boolean;
  isDarkTheme?: boolean;
  hideContactUsBtn?: boolean;
  bookNowCTAStickyNav?: boolean;
};

export const Header: React.FC<HeaderProps> = ({
  Logo,
  Buttons,
  MobileButtons,
  links,
  mobileLinks,
  locationMap,
  theme = "dark",
  headerAlwaysVisible = false,
  bookNowLabel,
  bookNowStyle,
  bookNowBookingWidget,
  bookNowMobileLabel,
  hoverLocationId,
  sortedLocations,
  setHoverLocationId,
  tabs,
  bannerStickyBar,
  viewAllLocation,
  isBrowser,
  isInterstitialPage = false,
  singleMobileLink,
  isBannerStickyBar,
  isDarkTheme = false,
  hideContactUsBtn = false,
  bookNowCTAStickyNav,
}: PropsWithChildren<HeaderProps>) => {
  const bookNowVisible = !!bookNowLabel && !!bookNowStyle;

  const classes = useStyles(
    theme,
    headerAlwaysVisible,
    bookNowVisible,
    bannerStickyBar,
  );
  const [expandedLink, setExpandedLink] = useState<HeaderLink | null>(null);
  const [isLinkExpanded, setIsLinkExpanded] = useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const scrollDir = useScrollDirection();
  const [isShowingDesktopBookNowWidget, setIsShowingDesktopBookNowWidget] =
    useState<boolean>(false);
  const [isJSDisabled, setIsJSDisabled] = useState(true);

  const timer = React.useRef<number | undefined>();

  const [
    isShowingDesktopBookNowWidgetDelayed,
    setIsShowingDesktopBookNowWidgetDelayed,
  ] = useState<boolean>(false);

  const { activeExperiments } = useActiveExperimentsStateSelector();

  useEffect(() => {
    // on unmount
    return () => {
      if (timer && timer.current) {
        window.clearTimeout(timer.current);
      }
    };
  }, []);

  const onSetMenuOpen = (open: boolean) => {
    setIsShowingDesktopBookNowWidget(open);
    if (scrollDir === ScrollDirections.Static && open === true) {
      // to allow time for fade in animation before re-rendering
      const timeoutId = timer.current;
      if (timeoutId && timer) {
        timer.current = undefined;
        window.clearTimeout(timeoutId);
      }
      timer.current = window.setTimeout(
        () => setIsShowingDesktopBookNowWidgetDelayed(open),
        150,
      );
    } else {
      setIsShowingDesktopBookNowWidgetDelayed(open);
    }
  };

  const showMap = () => {
    return (
      <Grid item xs={12} md={5} className={classes.locationMap}>
        {locationMap}
      </Grid>
    );
  };

  const getExpandedMenuSize = (link: HeaderLink): number | null => {
    const cards = link?.content?.cards;
    const subnavs = link?.content?.subnavs;

    if (cards || subnavs) {
      const itemsLength = Object.values(link?.content || {})
        .flatMap((item) => item?.length || 0)
        .reduce((itemA, itemB) => itemA + itemB);
      const menuSize = locationMap ? itemsLength * 50 : itemsLength * 25;

      return menuSize <= 100 ? menuSize : 100;
    }

    return null;
  };

  useEffect(() => {
    setIsJSDisabled(false);
  }, []);

  const bookNowCTAStickyNavigation =
    !bookNowCTAStickyNav ||
    (scrollDir === ScrollDirections.Up && headerAlwaysVisible);

  return (
    <>
      <div
        className={classnames(classes.root, {
          [classes.scrolled]:
            scrollDir === ScrollDirections.Down && headerAlwaysVisible,
          [classes.sticky]:
            scrollDir === ScrollDirections.Up && headerAlwaysVisible,
          [classes.solidBackground]:
            scrollDir === ScrollDirections.Up || isShowingDesktopBookNowWidget,
          [classes.solidWhiteBackground]: isDarkTheme,
          [classes.solidBackgroundWithGradient]: isInterstitialPage,
        })}
        data-testid="root"
      >
        {bannerStickyBar && isBannerStickyBar && <>{bannerStickyBar}</>}
        <Container
          className={classnames(classes.containerRoot, {
            [classes.customContainerRoot]: isDarkTheme,
          })}
        >
          <nav aria-label="Main Navigation">
            <Grid
              container
              alignItems="center"
              className={classes.headerContainer}
            >
              <Grid item>
                {Logo && (
                  <div className={classes.logo}>
                    <Logo />
                  </div>
                )}
              </Grid>
              <Grid item xs>
                <div
                  className={classnames(classes.headerNav, {
                    [classes.headerNavHover]: isJSDisabled,
                  })}
                >
                  <ul>
                    {links?.map((link: HeaderLink, index: number) => {
                      const linksContentSize = getExpandedMenuSize(link);
                      return (
                        <ClickAwayListener
                          onClickAway={() => {
                            if (link === expandedLink) {
                              setIsLinkExpanded(false);
                              setExpandedLink(null);
                            }
                          }}
                          key={`link-${index}`}
                        >
                          <li
                            className={
                              !!linksContentSize && linksContentSize <= 25
                                ? classes.relative
                                : undefined
                            }
                          >
                            <Link
                              href={link?.url || ""}
                              data-testid="link"
                              onClick={(event: SyntheticEvent) => {
                                if (
                                  link?.content.cards?.length > 0 ||
                                  link?.content.subnavs?.length > 0
                                ) {
                                  event.preventDefault();
                                  link === expandedLink
                                    ? setExpandedLink(null)
                                    : setExpandedLink(link);
                                  setIsLinkExpanded(true);
                                }
                              }}
                              className={classNames(classes.headerLink, {
                                [classes.headerLinkExpanded]:
                                  link === expandedLink,
                                [classes.linkHasDropdown]:
                                  link?.content?.cards?.length > 0 ||
                                  link?.content?.subnavs?.length > 0,
                                [classes.headerLinkRoot]: isDarkTheme,
                                [classes.headerLinkExpandedExp]:
                                  isDarkTheme && link === expandedLink,
                              })}
                              underline="hover"
                            >
                              <Typography component="span" color="inherit">
                                <b>{link.label}</b>
                              </Typography>
                              {(link?.content?.cards?.length > 0 ||
                                link?.content?.subnavs?.length > 0) &&
                                (link === expandedLink ? (
                                  <ExpandLess color="inherit" />
                                ) : (
                                  <ExpandMore color="inherit" />
                                ))}
                            </Link>
                            {!link?.content &&
                              (link?.content?.cards?.length > 0 ||
                                link?.content?.subnavs?.length > 0) && (
                                <div
                                  className={classnames(
                                    `${classes.expandedContent} ${
                                      !!linksContentSize &&
                                      linksContentSize <= 25
                                        ? classes.centered
                                        : ""
                                    }`,
                                    {
                                      [classes.expandedContentExp]: isDarkTheme,
                                    },
                                  )}
                                  style={{
                                    maxWidth:
                                      !!linksContentSize &&
                                      linksContentSize > 25 &&
                                      linksContentSize < 100
                                        ? `${linksContentSize}%`
                                        : undefined,
                                  }}
                                >
                                  {link?.content?.cards && (
                                    <>
                                      {link?.content?.cards.map(
                                        (card: HeaderCard, index: number) => (
                                          <Grid
                                            item
                                            key={`card-item-${index}`}
                                            className={classes.flexAdjust}
                                          >
                                            <HeaderSubNavButtonCard
                                              svgImgUrl={card?.imgUrl}
                                              index={`exlink-${index}`}
                                              title={card?.title}
                                              description={card?.description}
                                              linkUrl={card?.link?.url}
                                              linkLabel={card?.link?.label}
                                              isDarkTheme={isDarkTheme}
                                            />
                                          </Grid>
                                        ),
                                      )}
                                    </>
                                  )}

                                  {link?.content?.subnavs && (
                                    <>
                                      {link?.content?.subnavs.map(
                                        (
                                          subnav: HeaderSubnav,
                                          index: number,
                                        ) => (
                                          <Grid
                                            key={`subnav-item-${index}`}
                                            item
                                            className={classes.flexAdjust}
                                          >
                                            {Array.isArray(subnav) ? (
                                              <>
                                                {subnav.map((nav) => (
                                                  <>
                                                    {" "}
                                                    {nav && (
                                                      <HeaderSubNavLinksCard
                                                        index={`subNav-${index}`}
                                                        subnavLinks={nav}
                                                        setHoverLocationId={
                                                          setHoverLocationId
                                                        }
                                                        sortedLocations={
                                                          sortedLocations
                                                        }
                                                        hoverLocationId={
                                                          hoverLocationId
                                                        }
                                                        isBrowser={isBrowser}
                                                        isDarkTheme={
                                                          isDarkTheme
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                ))}{" "}
                                              </>
                                            ) : (
                                              <HeaderSubNavLinksCard
                                                index={`subNav-${index}`}
                                                subnavLinks={subnav}
                                                setHoverLocationId={
                                                  setHoverLocationId
                                                }
                                                sortedLocations={
                                                  sortedLocations
                                                }
                                                hoverLocationId={
                                                  hoverLocationId
                                                }
                                                isBrowser={isBrowser}
                                                isDarkTheme={isDarkTheme}
                                              />
                                            )}
                                          </Grid>
                                        ),
                                      )}
                                    </>
                                  )}
                                </div>
                              )}
                            {link?.content &&
                              (link?.content?.cards?.length > 0 ||
                                link?.content?.subnavs?.length > 0) && (
                                <div
                                  className={classnames(
                                    `${classes.expandedContent} ${
                                      !!linksContentSize &&
                                      linksContentSize <= 25
                                        ? classes.centered
                                        : ""
                                    }`,
                                    {
                                      [classes.expandedContentExp]: isDarkTheme,
                                    },
                                  )}
                                  style={{
                                    display:
                                      link === expandedLink ||
                                      (isLinkExpanded &&
                                        ((expandedLink &&
                                          expandedLink?.content &&
                                          link === expandedLink) ||
                                          !isLinkExpanded))
                                        ? "flex"
                                        : "none",
                                    maxWidth:
                                      !!linksContentSize &&
                                      linksContentSize > 25 &&
                                      linksContentSize < 100
                                        ? `${linksContentSize}%`
                                        : undefined,
                                  }}
                                >
                                  {link?.content?.cards && (
                                    <>
                                      {link?.content?.cards.map(
                                        (card: HeaderCard, index: number) => (
                                          <Grid
                                            item
                                            key={`card-item-${index}`}
                                            className={classes.flexAdjust}
                                          >
                                            <HeaderSubNavButtonCard
                                              svgImgUrl={card?.imgUrl}
                                              index={`exlink-${index}`}
                                              title={card?.title}
                                              description={card?.description}
                                              linkUrl={card?.link?.url}
                                              linkLabel={card?.link?.label}
                                              isDarkTheme={isDarkTheme}
                                            />
                                          </Grid>
                                        ),
                                      )}
                                    </>
                                  )}
                                  {link?.content?.subnavs && (
                                    <>
                                      {link?.content?.subnavs.map(
                                        (
                                          subnav: HeaderSubnav,
                                          index: number,
                                        ) => (
                                          <Grid
                                            key={`subnav-item-${index}`}
                                            item
                                            className={classes.flexAdjust}
                                          >
                                            {Array.isArray(subnav) ? (
                                              <>
                                                {subnav.map((nav, idx) => (
                                                  <React.Fragment
                                                    key={`nav-link-${idx}`}
                                                  >
                                                    {" "}
                                                    {nav && (
                                                      <HeaderSubNavLinksCard
                                                        index={`subNav-${index}`}
                                                        subnavLinks={nav}
                                                        setHoverLocationId={
                                                          setHoverLocationId
                                                        }
                                                        sortedLocations={
                                                          sortedLocations
                                                        }
                                                        hoverLocationId={
                                                          hoverLocationId
                                                        }
                                                        isBrowser={isBrowser}
                                                        isDarkTheme={
                                                          isDarkTheme
                                                        }
                                                      />
                                                    )}
                                                  </React.Fragment>
                                                ))}{" "}
                                              </>
                                            ) : (
                                              <HeaderSubNavLinksCard
                                                index={`subNav-${index}`}
                                                subnavLinks={subnav}
                                                setHoverLocationId={
                                                  setHoverLocationId
                                                }
                                                sortedLocations={
                                                  sortedLocations
                                                }
                                                hoverLocationId={
                                                  hoverLocationId
                                                }
                                                isBrowser={isBrowser}
                                                isDarkTheme={isDarkTheme}
                                              />
                                            )}
                                          </Grid>
                                        ),
                                      )}
                                    </>
                                  )}
                                  {link?.showLocationMap && showMap()}
                                </div>
                              )}
                          </li>
                        </ClickAwayListener>
                      );
                    })}
                  </ul>
                </div>
              </Grid>
              <Grid
                item
                className={classNames(classes.headerButtons, {
                  [classes.headerButtonsExp]: isDarkTheme,
                  [classes.bookNowCTAStickyRoot]: bookNowCTAStickyNav,
                  [classes.bookNowCTASticky]: !bookNowCTAStickyNavigation,
                })}
              >
                {Buttons && !hideContactUsBtn && (
                  <Hidden mdDown>
                    <Buttons />
                  </Hidden>
                )}

                {bookNowVisible &&
                  !activeExperiments?.hideBookNowButton &&
                  bookNowCTAStickyNavigation && (
                    <>
                      {isDarkTheme ? (
                        <ThemeProvider theme={SereneUI}>
                          <BookNowButton
                            label={bookNowLabel}
                            mobileWidgetLabel={bookNowMobileLabel}
                            onSetMenuOpen={onSetMenuOpen}
                          >
                            <>{bookNowBookingWidget}</>
                          </BookNowButton>
                        </ThemeProvider>
                      ) : (
                        <BookNowButton
                          label={bookNowLabel}
                          mobileWidgetLabel={bookNowMobileLabel}
                          onSetMenuOpen={onSetMenuOpen}
                        >
                          <>{bookNowBookingWidget}</>
                        </BookNowButton>
                      )}
                    </>
                  )}
              </Grid>
              {!isInterstitialPage && (mobileLinks || singleMobileLink) ? (
                <Grid
                  item
                  className={classnames(classes.menuButtonContainer, {
                    [classes.menuButtonContainerExp]: isDarkTheme,
                  })}
                >
                  <IconButton
                    className={classnames(classes.menuButton, {
                      [classes.menuButtonDark]: isDarkTheme,
                    })}
                    onClick={() => setOpen(true)}
                  >
                    <Menu />
                    {!isDarkTheme && <>Menu</>}
                  </IconButton>
                </Grid>
              ) : (
                !hideContactUsBtn && (
                  <Grid item className={classes.singleMenuButtonContainer}>
                    <Buttons />
                  </Grid>
                )
              )}
            </Grid>
          </nav>
        </Container>
        <Hidden mdDown>
          {isShowingDesktopBookNowWidgetDelayed && (
            <div className={classes.bookNowWidgetContainer}>
              {isDarkTheme ? (
                <ThemeProvider theme={SereneUI}>
                  <>{bookNowBookingWidget}</>
                </ThemeProvider>
              ) : (
                <>{bookNowBookingWidget}</>
              )}
            </div>
          )}
        </Hidden>
      </div>

      {(mobileLinks || singleMobileLink) && (
        <SlidingMobileMenu
          links={mobileLinks ? mobileLinks : singleMobileLink}
          Buttons={MobileButtons}
          open={open}
          setOpen={setOpen}
          locationMap={locationMap}
          locationMapLabel={tabs.locationMapLabel}
          locationListLabel={tabs.locationListLabel}
          viewAllLocation={viewAllLocation}
          isDarkTheme={isDarkTheme}
        />
      )}
    </>
  );
};
